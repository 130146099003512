import axios from "axios";
const global = require("../config/config");

async function getAllTestemunhos(t, destaque, infoJson) {
  try {
    let arr = {};

    if (t) {
      arr = { ...arr, top: t };
    }
    if (destaque) {
      arr = { ...arr, spotlight: destaque };
    }

    const API_URL_GET_TESTEMUNHOS = {
      method: "GET",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/GetTestemunhos/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
        ...arr,
      },
      headers: { token: process.env.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_GET_TESTEMUNHOS);

    if (res.data.length > 0) {
      const Testemunhos = res.data.map((item) => {
        return { ...item };
      });
      return Testemunhos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

async function insertTestemunhos(dados, infoJson) {
  try {
    const API_URL_INSERT_TESTEMUNHOS = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/easybuy/InsertTestemunhos/`,
      params: {
        dealer_id: infoJson.Stand.Anunciante,
      },
      headers: {
        "Content-Type": "application/json",
        token: process.env.REACT_APP_TOKEN_API,
      },

      data: dados,
    };

    return await axios
      .request(API_URL_INSERT_TESTEMUNHOS)
      .then((data) => {
        return data.status;
      })
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.error("error", error);
  }
}

export { getAllTestemunhos, insertTestemunhos };
