import React from "react";
import { Form } from "react-bootstrap";

export default function EasybuyAno({
  errors,
  handleChange,
  touched,
  setFieldValue,
}) {
  const year = new Date().getFullYear();

  const onChangeReset = () => {
    setFieldValue("marca.nome", "");
    setFieldValue("marca.id", "");
    setFieldValue("modelo.nome", "");
    setFieldValue("modelo.id", "");
    setFieldValue("combustivel.nome", "");
    setFieldValue("combustivel.id", "");
    setFieldValue("potencia", "");
    setFieldValue("cilindrada", "");
    setFieldValue("porta.nome", "");
    setFieldValue("porta.id", "");
    setFieldValue("transmissao.nome", "");
    setFieldValue("transmissao.id", "");
    setFieldValue("versao.nome", "");
    setFieldValue("versao.id", "");
    setFieldValue("lugar.nome", "");
    setFieldValue("lugar.id", "");
  };

  return (
    <>
      <Form.Select
        name={handleChange.name}
        onBlur={handleChange.onBlur}
        onChange={(e) => {
          onChangeReset();
          handleChange.onChange(e);
        }}
        isInvalid={!!errors.ano && touched.ano}
        isValid={handleChange.value !== ""}
        value={handleChange.value}
      >
        <option value="">Selecione</option>
        {Array.from(new Array(10), (v, i) => (
          <option key={i} value={year - i}>
            {year - i}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{errors.ano}</Form.Control.Feedback>
    </>
  );
}
