import React, { useEffect, useState } from "react";
import * as apiNoticias from "../../../../api/apiNoticias";
import { Col, Container } from "react-bootstrap";
import SectionZone from "../SectionZone";
import CarrouselTestemunhos from "./CarrouselTestemunhos";
import { ReactComponent as Mais } from "../../../../assets/icons/mais.svg";
import css from "../destaques/destaques.module.css";
import { Link } from "react-router-dom";

export default function TestemunhosHome({ infoJson, allinfoText }) {
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });
  const [allTestemunhos, setAllTestemunhos] = useState([]);

  useEffect(() => {
    allinfoText.Testemunhos && setAlltexto(allinfoText.Testemunhos);
  }, [allinfoText]);

  useEffect(() => {
    const getAllTestemunhos = async () => {
      const ArrayTestemunho = await apiNoticias.getAllTestemunhos(
        infoJson.Layout.Testemunhos.top,
        infoJson.Layout.Testemunhos.destaque,
        infoJson
      );

      console.log("ArrayTestemunho", ArrayTestemunho);

      setAllTestemunhos(ArrayTestemunho);
    };
    getAllTestemunhos();
  }, [infoJson]);

  return (
    <>
      {allTestemunhos.length !== 0 && (
        <SectionZone
          Titulo={
            alltexto.Titulo !== "" ? alltexto.Titulo : "A opinião dos clientes"
          }
          SubTitulo={
            alltexto.SubTitulo !== "" ? alltexto.SubTitulo : "testemunhos"
          }
          top={10}
          bottom={0.0001}
        >
          <Container fluid="xxxl" className={`noPadding-xs position-relative`}>
            <CarrouselTestemunhos
              infoJson={infoJson}
              allTestemunhos={allTestemunhos}
            />
          </Container>
          {infoJson.Layout.Testemunhos.btn && (
            <Link
              className={css.padding}
              to={
                allinfoText.Testemunhos && allinfoText.Testemunhos.Url !== ""
                  ? allinfoText.Testemunhos.Url
                  : infoJson.Testemunhos.url
              }
            >
              <Col xs={12} className={`d-flex justify-content-center`}>
                <button className={`btnPesquisa ${css.btnDireccoes}`}>
                  <Mais
                    height="18px"
                    width="18px"
                    stroke="#fff"
                    style={{ marginRight: "5px" }}
                  />
                  Ver / Inserir Testemunhos
                </button>
              </Col>
            </Link>
          )}
        </SectionZone>
      )}
    </>
  );
}
