import * as Yup from "yup";

const ValuesSchema = Yup.object().shape({
  matricula: Yup.string().required("Por favor insira A Matrícula"),
  ano: Yup.string().required("Por favor insira o Ano"),
  mes: Yup.string().required("Por favor insira o Mês"),
  marca: Yup.object().shape({
    id: Yup.string().required("Por favor insira o Marca"),
    nome: Yup.string().required("Por favor insira o Marca"),
  }),
  modelo: Yup.object().shape({
    id: Yup.string().required("Por favor insira o Modelo"),
    nome: Yup.string().required("Por favor insira o Modelo"),
  }),
  combustivel: Yup.object().shape({
    id: Yup.string().required("Por favor insira o Combustível"),
    nome: Yup.string().required("Por favor insira o Combustível"),
  }),
  potencia: Yup.number("Por favor insira valor numerico").required(
    "Por favor insira o Potência"
  ),
  cilindrada: Yup.number("Por favor insira valor numerico").required(
    "Por favor insira o Cilindrada"
  ),
  porta: Yup.object().shape({
    id: Yup.string().required("Por favor insira o Portas"),
    nome: Yup.string().required("Por favor insira o Portas"),
  }),
  transmissao: Yup.object().shape({
    id: Yup.string().required("Por favor insira o Transmissão"),
    nome: Yup.string().required("Por favor insira o Transmissão"),
  }),
  versao: Yup.object().shape({
    id: Yup.string(),
    nome: Yup.string().required("Por favor insira a Versão"),
  }),
  km: Yup.number()
    .max(100000, "Não é permitido mais que 100 000 kms")
    .required("Por favor insira o Quilómetros"),
  lugar: Yup.object().shape({
    id: Yup.string().required("Por favor insira a Lugares"),
    nome: Yup.string().required("Por favor insira a Lugares"),
  }),
  cor: Yup.object().shape({
    id: Yup.string().required("Por favor insira a Lugares"),
    nome: Yup.string().required("Por favor insira a Lugares"),
  }),
  metalizado: Yup.string(),
  extras: Yup.array(),
  historico: Yup.object().shape({
    primeiroDono: Yup.string().required("Por favor insira a Primeiro Dono"),
    livroRevisao: Yup.string().required("Por favor insira a Livro de Revisões"),
    fumador: Yup.string().required("Por favor insira o Veiculo de Fumador"),
    importado: Yup.string().required("Por favor insira o Veiculo Importado"),
    acidentes: Yup.string().required("Por favor insira o Acidentes Reportados"),
    segundaChave: Yup.string().required(
      "Por favor insira o Tem segunda chave?"
    ),
  }),
  pneus: Yup.object().shape({
    dianteiros: Yup.string().required(
      "Por favor insira o estado dos Pneus Dianteiros"
    ),
    traseiros: Yup.string().required(
      "Por favor insira o estado dos Pneus Traseiros"
    ),
  }),
  danos: Yup.array(),
  textDanos: Yup.string(),
  nome: Yup.string()
    .max(25, "O Nome só pode ter 25 digitos")
    .required("Por favor insira o Nome"),
  email: Yup.string()
    .email("Formato do Email está invalido")
    .required("Por favor insira o Email"),
  telemovel: Yup.number("Por favor insira valor numerico").required(
    "Por favor insira o número de Telemovel"
  ),
  distrito: Yup.object().shape({
    id: Yup.string().required("Por favor insira o Distrito"),
    nome: Yup.string().required("Por favor insira o Distrito"),
  }),
  concelho: Yup.object().shape({
    id: Yup.string().required("Por favor insira o Concelho"),
    nome: Yup.string().required("Por favor insira o Concelho"),
  }),
  valorPretende: Yup.number("Por favor insira valor numerico").required(
    "Por favor insira o Valor Pretendido"
  ),
  motivoVenda: Yup.string().required("Por favor insira o Motivo de Venda"),
  terms: Yup.bool().required().oneOf([true]),
});

export default ValuesSchema;
