import React, { useState } from "react";
import { Form, Col, Row, FloatingLabel } from "react-bootstrap";
import InputMask from "react-input-mask-3.0";
import EasybuyCombustivel from "./1Parte/EasybuyCombustivel";
import EasybuyMarcas from "./1Parte/EasybuyMarcas";
import EasybuyModelos from "./1Parte/EasybuyModelos";
import EasybuyAno from "./1Parte/EasybuyAno";
import EasybuyMes from "./1Parte/EasybuyMes";
import EasybuyPotencia from "./1Parte/EasybuyPotencia";
import EasybuyCilindrada from "./1Parte/EasybuyCilindrada";
import EasybuyPortas from "./1Parte/EasybuyPortas";
import EasybuyTransmissao from "./1Parte/EasybuyTransmissao";
import EasybuyVersao from "./1Parte/EasybuyVersao";
import * as apiCarros from "../../../../../api/apiCarros";
import ast from "../../../../../assets/icons/easybuy/ast.svg";
import { getIn } from "formik";
import EasybuyLugares from "./1Parte/EasybuyLugares";
import EasybuyVersaoMatricula from "./1Parte/EasybuyVersaoMatricula";

export default function PrimeiraParte({
  touched,
  getFieldProps,
  setFieldValue,
  values,
  errors,
  infoJson,
  setExtras,
}) {
  const [allModelo, setAllModelo] = useState([]);
  const [allVersoesMatricula, setAllVersoesMatricula] = useState([]);
  const [allVersoesSelected, setAllVersoesSelected] = useState([]);

  const onChangeMarcas = async (event) => {
    event.persist();

    if (event.target.value !== "") {
      const modelos = await apiCarros.getModeloEurotax(
        event.target.value,
        values.ano
      );

      if (modelos.length !== 0) {
        setAllModelo([
          ...modelos,
          {
            CodModelo: "Outra Opção",
            Modelo: "Outra Opção",
          },
        ]);
        if (modelos.length === 1) {
          setFieldValue("modelo.id", modelos[0].CodModelo);
          setFieldValue("modelo.nome", modelos[0].Modelo);
        }
      } else {
        const modelos = await apiCarros.getAllModelos(
          event.target.value,
          infoJson
        );
        setAllModelo(modelos);
      }
    } else {
      setAllModelo("");
    }
  };

  const onChangeModelos = async (event) => {
    event.persist();

    if (event.target.value === "Outra Opção") {
      const modelos = await apiCarros.getAllModelos(values.marca.id, infoJson);
      setAllModelo(modelos);
      setFieldValue("modelo.id", "");
      setFieldValue("modelo.nome", "");
    }
  };

  const matriculaCatalogacao = async (e) => {
    const result = await apiCarros.GetMatSearchEurotax(e.target.value);
    if (result.Ano) {
      setFieldValue("ano", result?.Ano);
      setAllVersoesMatricula(result?.Versoes);
    }
  };

  return (
    <Row>
      <Col xs={12} md={6} lg={4}>
        {infoJson.Stand.Matricula ? (
          <FloatingLabel
            label={
              <>
                <img src={ast} alt="asterisco" />
                Matrícula
              </>
            }
            className="mb-4"
            controlId="formGridMatrícula"
          >
            <Form.Control
              placeholder="Escreva aqui"
              as={InputMask}
              mask={"**-**-**"}
              maskPlaceholder="*"
              name={getFieldProps("matricula").name}
              onChange={(e) => getFieldProps("matricula").onChange(e)}
              value={getFieldProps("matricula").value}
              onBlur={(e) => {
                matriculaCatalogacao(e);
                getFieldProps("matricula").onBlur(e);
              }}
              isInvalid={!!errors.matricula && touched.matricula}
              isValid={values.matricula !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.matricula}
            </Form.Control.Feedback>
          </FloatingLabel>
        ) : (
          <FloatingLabel
            label={
              <>
                <img src={ast} alt="asterisco" />
                Matrícula
              </>
            }
            className="mb-4"
            controlId="formGridMatrícula"
          >
            <Form.Control
              placeholder="Escreva aqui"
              as={InputMask}
              mask={"**-**-**"}
              maskPlaceholder="*"
              {...getFieldProps("matricula")}
              isInvalid={!!errors.matricula && touched.matricula}
              isValid={values.matricula !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.matricula}
            </Form.Control.Feedback>
          </FloatingLabel>
        )}
      </Col>

      <Col xs={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Mês
            </>
          }
          className="mb-4"
          controlId="formGridMês"
        >
          <EasybuyMes
            errors={errors}
            handleChange={getFieldProps("mes")}
            touched={touched}
          />
        </FloatingLabel>
      </Col>
      <Col xs={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Ano
            </>
          }
          className="mb-4"
          controlId="formGridAno"
        >
          <EasybuyAno
            errors={errors}
            handleChange={getFieldProps("ano")}
            setFieldValue={setFieldValue}
            touched={touched}
          />
        </FloatingLabel>
      </Col>
      {allVersoesMatricula.length !== 0 && (
        <Col xs={12} lg={8}>
          <FloatingLabel
            label={
              <>
                <img src={ast} alt="asterisco" />
                Versão Recomendação
              </>
            }
            className="mb-4"
            controlId="formGridVersao"
          >
            <EasybuyVersaoMatricula
              allVersoesSelected={allVersoesSelected}
              setAllVersoesSelected={setAllVersoesSelected}
              allVersoesMatricula={allVersoesMatricula}
            />
          </FloatingLabel>
        </Col>
      )}
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Marca
            </>
          }
          className="mb-4"
          controlId="formGridMarcas"
        >
          <EasybuyMarcas
            infoJson={infoJson}
            onChangeMarcas={onChangeMarcas}
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={getFieldProps("marca.id")}
            touched={touched}
            values={values}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Modelo
            </>
          }
          className="mb-4"
          controlId="formGridModelo"
        >
          {allModelo.length === 0 ? (
            <>
              <Form.Select
                {...getFieldProps("modelo.id")}
                isInvalid={
                  Boolean(getIn(errors, "modelo.id")) &&
                  Boolean(getIn(touched, "modelo.id"))
                }
                disabled
              >
                <option value="">Selecione</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "modelo.id")}
              </Form.Control.Feedback>
            </>
          ) : (
            <EasybuyModelos
              allModelo={allModelo}
              errors={errors}
              setFieldValue={setFieldValue}
              handleChange={getFieldProps("modelo.id")}
              touched={touched}
              values={values}
              onChangeModelos={onChangeModelos}
              allVersoesSelected={allVersoesSelected}
            />
          )}
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Combustível
            </>
          }
          className="mb-4"
          controlId="formGridCombustivel"
        >
          <EasybuyCombustivel
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={getFieldProps("combustivel.id")}
            touched={touched}
            infoJson={infoJson}
            values={values}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Potência
            </>
          }
          className="mb-4"
          controlId="formGridPotencia"
        >
          <EasybuyPotencia
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={getFieldProps("potencia")}
            touched={touched}
            infoJson={infoJson}
            values={values}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Cilindrada
            </>
          }
          className="mb-4"
          controlId="formGridCilindrada"
        >
          <EasybuyCilindrada
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={getFieldProps("cilindrada")}
            touched={touched}
            infoJson={infoJson}
            values={values}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Nº de Portas
            </>
          }
          className="mb-4"
          controlId="formGridPortas"
        >
          <EasybuyPortas
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={getFieldProps("porta.id")}
            touched={touched}
            infoJson={infoJson}
            values={values}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Transmissão
            </>
          }
          className="mb-4"
          controlId="formGridtransmissao"
        >
          <EasybuyTransmissao
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={getFieldProps("transmissao.id")}
            touched={touched}
            infoJson={infoJson}
            values={values}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
      <Col xs={12} lg={allVersoesMatricula.length !== 0 ? 4 : 8}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Versão
            </>
          }
          className="mb-4"
          controlId="formGridVersao"
        >
          <EasybuyVersao
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={getFieldProps("versao.nome")}
            touched={touched}
            infoJson={infoJson}
            values={values}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={allVersoesMatricula.length !== 0 ? 4 : 8}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Quilómetros
            </>
          }
          className="mb-4"
          controlId="formGridQuilometros"
        >
          <Form.Control
            type="number"
            placeholder="Escreva aqui"
            {...getFieldProps("km")}
            isValid={values.km !== ""}
            isInvalid={!!errors.km && touched.km}
          />
          <Form.Control.Feedback type="invalid">
            {errors.km}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FloatingLabel
          label={
            <>
              <img src={ast} alt="asterisco" />
              Lugares
            </>
          }
          className="mb-4"
          controlId="formGridLugares"
        >
          <EasybuyLugares
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={getFieldProps("lugar.id")}
            touched={touched}
            infoJson={infoJson}
            values={values}
            setExtras={setExtras}
            allVersoesSelected={allVersoesSelected}
          />
        </FloatingLabel>
      </Col>
    </Row>
  );
}
