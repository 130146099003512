import React from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import ast from "../../../../../assets/icons/easybuy/ast.svg";
import EasybuyDistritos from "./3Parte/EasybuyDistritos";
import * as apiCarros from "../../../../../api/apiCarros";
import { useState } from "react";
import EasybuyConcelhos from "./3Parte/EasybuyConcelhos";
export default function TerceiraParte({
  touched,
  getFieldProps,
  setFieldValue,
  values,
  errors,
  infoJson,
}) {
  const [allConcelho, setAllConcelho] = useState([]);

  const onChangeConcelho = async (event) => {
    event.persist();
    if (event.target.value !== "") {
      const Concelhos = await apiCarros.getAllConcelhos(
        event.target.value,
        infoJson
      );
      setAllConcelho(Concelhos);
    } else {
      setAllConcelho([]);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} lg={4}>
          <FloatingLabel
            label={
              <>
                <img src={ast} alt="asterisco" />
                Nome
              </>
            }
            controlId="formGridName"
            className="mb-4  "
          >
            <Form.Control
              type="text"
              placeholder="Escreva aqui"
              {...getFieldProps("nome")}
              isValid={values.nome !== ""}
              maxLength="25"
              isInvalid={!!errors.nome && touched.nome}
            />
            <Form.Control.Feedback type="invalid">
              {errors.nome}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
        <Col xs={12} lg={4}>
          <FloatingLabel
            label={
              <>
                <img src={ast} alt="asterisco" />
                Telemovel
              </>
            }
            controlId="formGridTelemovel"
            className="mb-4  "
          >
            <Form.Control
              type="number"
              maxLength="9"
              placeholder="Escreva aqui"
              max="9"
              {...getFieldProps("telemovel")}
              isValid={values.telemovel !== ""}
              isInvalid={!!errors.telemovel && touched.telemovel}
            />
            <Form.Control.Feedback type="invalid">
              {errors.telemovel}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
        <Col xs={12} lg={4}>
          <FloatingLabel
            className="mb-4 "
            label={
              <>
                <img src={ast} alt="asterisco" />
                Email
              </>
            }
            controlId="formGridEmail"
          >
            <Form.Control
              type="email"
              placeholder="Escreva aqui"
              {...getFieldProps("email")}
              isValid={values.email !== ""}
              isInvalid={!!errors.email && touched.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
        <Col xs={12} lg={4}>
          <FloatingLabel
            className="mb-4 "
            label={
              <>
                <img src={ast} alt="asterisco" />
                Distrito
              </>
            }
            controlId="formGridDistritos"
          >
            <EasybuyDistritos
              errors={errors}
              handleChange={getFieldProps("distrito.id")}
              setFieldValue={setFieldValue}
              touched={touched}
              infoJson={infoJson}
              onChangeConcelho={onChangeConcelho}
            />
          </FloatingLabel>
        </Col>
        <Col xs={12} lg={4}>
          <FloatingLabel
            className="mb-4 "
            label={
              <>
                <img src={ast} alt="asterisco" />
                Concelho
              </>
            }
            controlId="formGridConcelho"
          >
            <EasybuyConcelhos
              errors={errors}
              handleChange={getFieldProps("concelho.id")}
              setFieldValue={setFieldValue}
              touched={touched}
              infoJson={infoJson}
              allConcelho={allConcelho}
            />
          </FloatingLabel>
        </Col>
        <Col xs={12} lg={4}>
          <FloatingLabel
            label={
              <>
                <img src={ast} alt="asterisco" />
                Tem alguma ideia do valor pretende?
              </>
            }
            className="mb-4 interiorPage"
            controlId="formGridvalorPretende"
          >
            <Form.Control
              placeholder="Escreva aqui"
              type="number"
              {...getFieldProps("valorPretende")}
              isInvalid={!!errors.valorPretende && touched.valorPretende}
              isValid={values.valorPretende !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.valorPretende}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
        <Col xs={12} lg={4}>
          <FloatingLabel
            label={
              <>
                <img src={ast} alt="asterisco" />
                Motivo da venda
              </>
            }
            className="mb-4 interiorPage"
            controlId="formGridvalorPretende"
          >
            <Form.Select
              {...getFieldProps("motivoVenda")}
              isInvalid={!!errors.motivoVenda && touched.motivoVenda}
              isValid={values.motivoVenda !== ""}
            >
              <option value="">Selecione</option>
              <option value="1"> Trocar por outra viatura </option>
              <option value="0"> Venda da viatura </option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.motivoVenda}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
