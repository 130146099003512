import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

body {
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
}

a{
    color: ${({ theme }) => theme.text};  
}

a:hover{
  color: ${({ theme }) => theme.text};  
  text-decoration: none;
}
.SVGColor{
  fill:${({ theme }) => theme.text};  
  stroke:${({ theme }) => theme.text};  
}


.sectionFooter {
    background: ${({ theme }) => theme.boxCar};  
    border: 1.2px solid  ${({ theme }) => theme.border};  
    box-shadow: 0 13px 44px 0 rgba(0,0,0,0.06);
}

.boxShadowPares {
  background-color: ${({ theme }) => theme.boxCar};
  border: 2px solid ${({ theme }) => theme.linha};
}
.boxShadowPares > label {
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.boxCar};
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.nav-link:hover, .nav-link:focus {
  color: ${({ theme }) => theme.text};
}

.listagem_info{
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  -webkit-box-shadow:${({ theme }) => theme.boxShadow};
  border-radius: 7px;
}

.modal-header {
  border-bottom: none;
  box-shadow: 0 13px 44px 0 rgba(0,0,0,0.04);
}
.border-top {
  border-top: 2px  solid ${({ theme }) => theme.border} !important;
}

.modal-content {
  background-color: ${({ theme }) => theme.boxCar};
}

.form-select {
  box-shadow: none;
   background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.border};
  border-radius: 30px;
}

.form-select:disabled {
  background-color: ${({ theme }) => theme.boxCar};
}
.form-control:disabled {
  background-color: ${({ theme }) => theme.boxCar};
}


.form-control {
  box-shadow: none;
   background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.borderInput};
  border-radius: 30px;
}
.form-floating > .form-control::placeholder, 
.form-floating > .form-control-plaintext::placeholder {
  color: ${({ theme }) => theme.text}; 
}

.form-control:focus {
  border-color: ${({ theme }) => theme.border};
   background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  outline: 0;
  color: ${({ theme }) => theme.text};
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: ${({ theme }) => theme.text};
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: ${({ theme }) => theme.text};
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: ${({ theme }) => theme.text};
}

.btn-blinear {
  color:  ${({ theme }) => theme.btnPartilhaColor};
  fill:  ${({ theme }) => theme.btnPartilhaColor};
  stroke:  ${({ theme }) => theme.btnPartilhaColor};
  background-color: ${({ theme }) => theme.btnPartilhaBg};
}
.btn-blinear:hover,
.btn-blinear:focus,
.btn-blinear.active {
  background-color: ${({ theme }) => theme.btnPartilhaBgHover};
  color: ${({ theme }) => theme.btnPartilhaBgcolorHover};
  fill:  ${({ theme }) => theme.btnPartilhaBgcolorHover};
  stroke:  ${({ theme }) => theme.btnPartilhaBgcolorHover};
}

.sidebar-box .read-more {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 20px 0 0 0;
  border-bottom: 15px solid ${({ theme }) => theme.boxCar};
  background: -moz-linear-gradient(
    top,
    ${({ theme }) => theme.boxCar} 0,
    ${({ theme }) => theme.boxCar} 35%,
    ${({ theme }) => theme.boxCar} 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
  background: -webkit-linear-gradient(
    top,
     ${({ theme }) => theme.boxCar} 0,
    ${({ theme }) => theme.boxCar} 35%,
    ${({ theme }) => theme.boxCar} 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
  background: linear-gradient(
    to bottom,
     ${({ theme }) => theme.boxCar} 0,
    ${({ theme }) => theme.boxCar} 35%,
    ${({ theme }) => theme.boxCar} 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
}

.sidebar-box .read-more-footer {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 50px 0 0 0;
  border-bottom: 15px solid ${({ theme }) => theme.boxCar};
  background: -moz-linear-gradient(
    top,
    ${({ theme }) => theme.boxCar}00 0,
    ${({ theme }) => theme.boxCar}cc 35%,
    ${({ theme }) => theme.boxCar}fa 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
  background: -webkit-linear-gradient(
    top,
     ${({ theme }) => theme.boxCar}00 0,
    ${({ theme }) => theme.boxCar}cc 35%,
    ${({ theme }) => theme.boxCar}fa 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
  background: linear-gradient(
    to bottom,
     ${({ theme }) => theme.boxCar}00 0,
    ${({ theme }) => theme.boxCar}cc 35%,
    ${({ theme }) => theme.boxCar}fa 56%,
    ${({ theme }) => theme.boxCar} 100%
  );
}

.form-check-input:checked {
  background-color: ${(props) => props.infoJson.Cores.Principal};
  border-color: ${(props) => props.infoJson.Cores.Principal};
}
.titulo-page {
  color: ${(props) => props.infoJson.Cores.Principal};
}
.cor-titulo{
  color: ${(props) => props.infoJson.Cores.Principal};
}

.btnForm{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
}

.btnForm::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255,0.12);
  mix-blend-mode: lighten;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}

.btnForm:hover:before {
  opacity: 1;
}

.btnForm:focus:before {
  background-color: rgba(0, 0, 0,0.12);
  opacity: 1;
}

.btnForm:disabled{
  opacity: 0.5 !important;;
}

.cookies{
  background-color: ${(props) => props.infoJson.Cores.Principal}e6 !important;
}


.titulo-newsletter{
  color: ${({ theme }) => theme.text};
}

.box-obrigado{
  background: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadowOb};
	height: 80px;
	line-height: 80px;
  text-align: center;
} 

.nav-link {
  color: ${({ theme }) => theme.text};
}

.corFundoClaro {
  background-color: ${(props) => props.infoJson.Cores.FundoTransparentScroll}f0;
  box-shadow: 0 8px 44px 0 rgba(184,184,184,0.23);
}

.corFundoClaroBottom {
  background-color: ${({ theme }) => theme.body};
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.1);
}


.btnPesquisa{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
}
.btnPesquisa::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255,0.12);
  mix-blend-mode: lighten;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}
.btnPesquisa:hover:before {
  opacity: 1;
}

.btnPesquisa:focus:before {
  background-color: rgba(0, 0, 0,0.12);
  opacity: 1;
}

.navbar-scroll-custom-visivel {
  background-color: transparent;
  box-shadow: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}


.navbar-scroll-custom-visivel.navbar-light .navbar-nav .nav-link {
  color: ${(props) => props.infoJson.Cores.TextoTopoTransparente} ;
}

.navbar-light .navbar-nav .nav-link {
  stroke: ${(props) => props.infoJson.Cores.TextoTransparentScroll} ;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: ${({ theme }) => theme.text} ;
}
.menuMobile {  
  color: ${(props) =>
    props.infoJson.Cores.MenuResponsivo !== ""
      ? props.infoJson.Cores.MenuResponsivo
      : props.theme.text} ;
}
.menuMobile svg{  
  fill: ${(props) =>
    props.infoJson.Cores.MenuResponsivo !== ""
      ? props.infoJson.Cores.MenuResponsivo
      : props.theme.text} ;
}
.menuMobileCantact {  
color: ${(props) =>
  props.infoJson.Cores.MenuResponsivo !== ""
    ? props.infoJson.Cores.MenuResponsivo
    : props.theme.text} ;
stroke: ${(props) =>
  props.infoJson.Cores.MenuResponsivo !== ""
    ? props.infoJson.Cores.MenuResponsivo
    : props.theme.text} ;
}
.menuMobileCantact svg{ 
  height:26px;
}

.menuMobileCantact:hover,
.menuMobileCantact.active  {
  stroke: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  fill: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  color: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
}
.dot{
  background-color: ${(props) => props.infoJson.Cores.Principal};
  background: -moz-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%, ${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: -webkit-linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  background: linear-gradient(270deg, ${(props) =>
    props.infoJson.Cores.GradienteInicio} 0%,${(props) =>
  props.infoJson.Cores.GradienteFim} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${(
    props
  ) => props.infoJson.Cores.GradienteInicio}', endColorstr='${(props) =>
  props.infoJson.Cores.GradienteFim}',GradientType=1 );
}
.dot::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255,0.12);
  mix-blend-mode: lighten;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}
.dot:hover:before {
  opacity: 1;
}

.dot:focus:before {
  background-color: rgba(0, 0, 0,0.12);
  opacity: 1;
}

.dot-bg{
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  -webkit-box-shadow:${({ theme }) => theme.boxShadow};
  box-shadow: 0 13px 44px 0 rgba(0, 0, 0, 0.04);
}


.boxMenu  {
  stroke: #141414 ;
  color: #141414 ;
}


.boxMenu.disable  {
  fill:#A8A8A8;  
  stroke:#A8A8A8; 
  color: #A8A8A8 ; 
}
.boxMenu + span {
  background-image: linear-gradient(${(props) =>
    props.infoJson.Cores.HoverTopoTransparente}, ${(props) =>
  props.infoJson.Cores.HoverTopoTransparente});
}

.boxMenu:hover,
.boxMenu.active  {
  stroke: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  fill: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  color: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
}


.boxMenuMobile  {
  stroke: #000 ;
  color: #000 ;
}


.boxMenuMobile.disable  {
  fill:#A8A8A8;  
  stroke:#A8A8A8; 
  color: #A8A8A8 ; 
}
.boxMenuMobile + span {
  background-image: linear-gradient(${(props) =>
    props.infoJson.Cores.HoverTopoTransparente}, ${(props) =>
  props.infoJson.Cores.HoverTopoTransparente});
}

.boxMenuMobile:hover,
.boxMenuMobile.active  {
  stroke: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  fill: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
  color: ${(props) => props.infoJson.Cores.HoverTopoTransparente};
}


.navbar-scroll-custom-visivel .boxMenu{
  color:#000000;
  fill:#000000;
  stroke:#000000;
}
.navbar-scroll-custom-visivel .menuMobile {
  color:#000000;
}
.navbar-scroll-custom-visivel .menuMobile svg {
  fill:#000000;
  stroke:#000000;
}

.carousel__cell{
  background-color: ${({ theme }) => theme.body} !important;
}

.offcanvas{
  background-color: ${({ theme }) => theme.bodyModal};
}



.boxMenuItem  {
  color: ${({ theme }) => theme.text};
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
  border-bottom: 1px solid  ${({ theme }) => theme.lineOption};
}
.boxMenuItem:last-child  {
  border-bottom: none;
}
.boxMenuItem:hover,
.boxMenuItem.active  {
  color: ${(props) => props.infoJson.Cores.Principal};
  fill: ${(props) => props.infoJson.Cores.Principal};
  stroke: ${(props) => props.infoJson.Cores.Principal};
}

.dropdown-menu{
  background-color: ${(props) => props.infoJson.Cores.FundoTransparentScroll};
  color: ${({ theme }) => theme.text};
  box-shadow: 0 13px 44px 0 rgba(0,0,0,0.04);
}
.dropdown-item {
  color: ${({ theme }) => theme.text};
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
  color: ${({ theme }) => theme.text};
  font-weight: 700;
  background-color: ${({ theme }) => theme.InputPesquisa};
  border-color: transparent;
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
}
.nav-tabs .nav-item.nav-link, 
.nav-tabs .nav-link {
  color:  ${({ theme }) => theme.text};
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
  font-weight: 700;
}
.nav-tabs {

  background-color: ${({ theme }) => theme.InputPesquisa};
  border-bottom: none;
}
.tab-content{
  background-color: ${({ theme }) => theme.InputPesquisa};
}
.nav-tabs .nav-item:last-child{
  margin-left:auto;
  }
.nav-tabs .nav-link .pesquisa-tab{
  color: ${({ theme }) => theme.dotsAtive};
  fill: ${({ theme }) => theme.dotsAtive};
  stroke: ${({ theme }) => theme.dotsAtive};
}

.nav-tabs .nav-link.active  .pesquisa-tab,
.nav-tabs .nav-link:hover  .pesquisa-tab{
  color: ${(props) => props.infoJson.Cores.Principal};
  fill: ${({ theme }) => theme.text};
  stroke: ${({ theme }) => theme.text};
}
.nav-tabs .nav-link.active  .pesquisa-tab > div:last-child,
.nav-tabs .nav-link:hover  .pesquisa-tab > div:last-child{
  background-color: ${(props) => props.infoJson.Cores.Principal};

  width: 60%;
}

@media (max-width: 767px) { 
  .nav-tabs .nav-item:last-child{
    margin-left: initial;
    }
}

.box-ligame{
  background-color: ${({ theme }) => theme.InputPesquisaLiga}; 
  border: 1.2px solid ${({ theme }) => theme.border} !important;
  color: ${({ theme }) => theme.text};  
}


.MenuFooter{.form-floating > label, .form-floa

  -webkit-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.MenuFooter:hover{
  color: ${(props) => props.infoJson.Cores.Principal};

}
.rectangle-pontosVendas {
  border: 1.2px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: 0 13px 44px 0 rgba(0, 0, 0, 0.06);
}


.svg-footer{
  stroke:${({ theme }) => theme.text};
}



 .form-control {
  box-shadow: none;
   background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.linha};
  border-radius: 30px;
}

 .form-control:focus {
  border-color: ${({ theme }) => theme.borderInput};
  background-color: ${({ theme }) => theme.boxCar};
  box-shadow: ${({ theme }) => theme.boxShadow};
  outline: 0;
  color: ${({ theme }) => theme.text};
}

.form-floating > label,
 .form-floating > label {
  background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
}
 .form-select {
  box-shadow: none;
  background-color: ${({ theme }) => theme.boxCar};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.linha};
  border-radius: 30px;
}
.titulo-Reduzido{
  color: ${(props) => props.infoJson.Cores.tituloPesquisa};
}

.titulo-Reduzido-interior{
  color: ${({ theme }) => theme.text};
}


.boxMenuMore li{
  border-bottom: 1px solid  ${({ theme }) => theme.lineOption};
}

.menu{
  border: 1px solid  ${({ theme }) => theme.lineOption};
  background: ${({ theme }) => theme.boxCar};
}
.menu.disable {
  border-color: transparent !important;
  box-shadow: none !important;
}
.menu.disable  > div{
  fill:#A8A8A8;  
  stroke:#A8A8A8; 
  color: #A8A8A8 ; 
}

.menu.active {
  border-color: ${({ theme }) => theme.lineOption} !important;
  box-shadow: 0 7px 9px 0 rgb(0 0 0 / 3%) !important;
}



.menu.active > div{
  fill:${(props) => props.infoJson.Cores.Principal};  
  stroke:${(props) => props.infoJson.Cores.Principal}; 
  color: ${(props) => props.infoJson.Cores.Principal} ; 
}
.close{
  color:${({ theme }) => theme.text};
  fill:${({ theme }) => theme.text};
  stroke:${({ theme }) => theme.text};
}

::-webkit-scrollbar-track {
  background: ${({ theme }) => theme.boxCar};
}

::-webkit-scrollbar-track {
  border: 4px solid ${({ theme }) => theme.boxCar};
}

::-webkit-scrollbar {
  background-color:${({ theme }) => theme.boxCar};
  width: 10px;
}

.selectSvg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='10' viewBox='0 0 18 10'%3E%3Cdefs%3E%3Cfilter id='filter'  x='4750' y='705' width='18' height='10' filterUnits='userSpaceOnUse'%3E%3CfeFlood result='flood' flood-color=''/%3E%3CfeComposite result='composite' operator='in' in2='SourceGraphic'/%3E%3CfeBlend result='blend' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath class='cls-1' fill='%23${(
    props
  ) =>
    props.infoJson.Cores.SVG.replace(
      "#",
      ""
    )}' d='M4768,706.263L4766.73,705l-7.74,7.509-0.83-.8v0l-6.88-6.67-1.28,1.244c1.9,1.844,7.21,6.992,8.99,8.713C4760.31,713.72,4759.02,714.967,4768,706.263Z' transform='translate(-4750 -705)'/%3E%3C/svg%3E%0A");
}


`;
