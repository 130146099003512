import React from "react";
import { theme } from "../../../tools/email/templates/Signup/theme";
import { WithDebug } from "../../../tools/email/templates/HtmlEmail";
import { A, Span } from "react-html-email";

const dataNow = new Intl.DateTimeFormat("pt", {
  dateStyle: "long",
  timeStyle: "medium",
}).format(new Date());

export default function EmailDestinatarioEasybuy({
  campos,
  infoJson,
  camposEmail,
  allinfo,
  data,
}) {
  return (
    <>
      <WithDebug
        theme={theme}
        title={camposEmail.assunto}
        emailHeaderHref={`http://${infoJson.Stand.Website}`}
        emailHeaderText={() => {
          return (
            <>
              <Span>
                <h1 style={theme.emailHeader.emailHeaderText}>NOVA MENSAGEM</h1>
                <A
                  href={`http://${infoJson.Stand.Website}`}
                  textDecoration={theme.emailHeader.a.textDecoration}
                  style={theme.emailHeader.a}
                >
                  em {`${infoJson.Stand.Website}`}
                </A>
              </Span>
            </>
          );
        }}
        emailHeaderLogo={allinfo.LogotipoEasybuy}
        bodyHeaderText={() => {
          return (
            <>
              <p style={theme.emailBody.bodyHeader.small}>
                {campos.nome} inseriu uma viatura:
                <br></br>
                <b>
                  {campos.marca.nome} {campos.modelo.nome} com a matrícula{" "}
                  {campos.matricula}
                </b>
                <br></br>
                Codigo Oferta:
                <b>{data.cod_viatura}</b>
              </p>
            </>
          );
        }}
        bodyContentComponent={() => {
          return (
            <>
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>MENSAGEM ENVIADA</b> em{" "}
                {dataNow}
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                <b style={theme.emailHeader.color}>Dados da Viatura:</b> 
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Marca :
                <b>{campos.marca.nome}</b>          
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Modelo :
                <b>{campos.modelo.nome}</b>          
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Versão :
                <b>{campos.versao.nome}</b>          
              </p>
               <p style={theme.emailBody.bodyFooter.span}>
                Quilometros :
                <b>{campos.km}</b>          
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Ano :
                <b>{campos.ano}</b>          
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Matrícula :
                <b>{campos.matricula}</b>          
              </p>
             <p style={theme.emailBody.bodyFooter.span}>
                Valor Pretendido :
                <b>{campos.valorPretende}</b>          
              </p>
            <p style={theme.emailBody.bodyFooter.span}>
                Nome :
                <b>{campos.nome}</b>          
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Email :
                <b>{campos.email}</b>          
              </p>
              <p style={theme.emailBody.bodyFooter.span}>
                Telemóvel :
                <b>{campos.telemovel}</b>          
              </p>
            </>
          );
        }}
        bodyFooterText={() => {
          return (
            <>
              <p style={theme.emailBody.bodyFooter.span}>
                <b>{campos.nome}</b>
              </p>
              <p style={theme.emailBody.bodyFooter.spanNoMargin}>
                Telef:<b>{campos.telemovel}</b>
              </p>
              <p style={theme.emailBody.bodyFooter.spanNoMarginA}>
                Email: <b>{campos.email}</b>
              </p>
            </>
          );
        }}
        debug={false}
      />
    </>
  );
}
