import React from "react";
import { Col, Row } from "react-bootstrap";

import { ReactComponent as InicioOpiniao } from "../../../assets/icons/testemunhos/inicio_opiniao.svg";
import { ReactComponent as FimOpiniao } from "../../../assets/icons/testemunhos/fim_opiniao.svg";
import { ReactComponent as Star } from "../../../assets/icons/modal/start.svg";
import { ReactComponent as Opiniao } from "../../../assets/icons/testemunhos/opiniao.svg";
import check_on from "../../../assets/icons/easybuy/check_on.svg";
import unckeck_on from "../../../assets/icons/easybuy/unckeck_on.svg";

import { tratarDataManutencao } from "../../helpers/tratarInfoCarros";
import css from "./testemunho.module.css";
import { RatingStar } from "rating-star";
export default function CardTestemunhos({ item, infoJson }) {
  const data = tratarDataManutencao(item.Data);


  return (
    <>
      <Col className={`listagem_info ${css.box}`}>
        <Row>
          <Col xs={12} className="d-flex align-items-center mb-2">
            <div>
              <Opiniao
                stroke={infoJson.Cores.Principal}
                style={{ marginRight: "10px" }}
              />
            </div>
            <div>
              <div className={`${css.titulo} text-truncate`}>{item.Nome}</div>
              <div className={`${css.data} `}>{data}</div>
            </div>
          </Col>
          <hr></hr>
          <Col xs={12} lg={7}>
            <Col className={`${css.texto} mb-4`}>
              <h5>O que mais gostou</h5>
              <InicioOpiniao style={{ marginRight: "5px" }} />
              <span
                dangerouslySetInnerHTML={{
                  __html: item.MaisGostou,
                }}
              />
              <FimOpiniao style={{ marginLeft: "5px" }} />
            </Col>
            <Col className={`${css.texto} mb-4`}>
              <h5>O que menos gostou</h5>
              <InicioOpiniao style={{ marginRight: "5px" }} />
              <span
                dangerouslySetInnerHTML={{
                  __html: item.MenosGostou,
                }}
              />
              <FimOpiniao style={{ marginLeft: "5px" }} />
            </Col>
            <Col className={`${css.texto}  d-flex align-items-center`}>
              <img
                src={item.Recomendar ? check_on : unckeck_on}
                alt="Não"
                style={{ marginRight: "10px" }}
              />{" "}
              Recomendaria a nossa empresa
            </Col>
          </Col>
          <Col xs={12} lg={5}>
            <h2 className="fs-4"> Detalhes da Avaliação</h2>
            <div className="d-flex align-items-center justify-content-between fs-6">
              Tempo de resposta ao seu pedido de avaliação{" "}
              <RatingStar
                clickable={false}
                id={`111${item.CodVisita}`}
                maxScore={5}
                size={15}
                noBorder={true}
                rating={
                  item.TempoResposta === "" ? 1 : parseInt(item.TempoResposta)
                }
                starIcon={Star}
                colors={{
                  mask: "#f3cc00",
                  stroke: "#f3cc00",
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between fs-6">
              Esclarecimentos quanto ao processo de entrega da viatura à venda
              Fácil{" "}
              <RatingStar
                clickable={false}
                id={`112${item.CodVisita}`}
                maxScore={5}
                size={15}
                noBorder={true}
                rating={
                  item.ProcessoEntrega === ""
                    ? 1
                    : parseInt(item.ProcessoEntrega)
                }
                starIcon={Star}
                colors={{
                  mask: "#f3cc00",
                  stroke: "#f3cc00",
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between fs-6">
              Pagamento da viatura{" "}
              <RatingStar
                clickable={false}
                id={`113${item.CodVisita}`}
                maxScore={5}
                size={15}
                noBorder={true}
                rating={
                  item.PagamentoViatura === ""
                    ? 1
                    : parseInt(item.PagamentoViatura)
                }
                starIcon={Star}
                colors={{
                  mask: "#f3cc00",
                  stroke: "#f3cc00",
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between fs-6">
              Se for o caso liquidação do processo a uma financeira/Banco{" "}
              <RatingStar
                clickable={false}
                id={`114${item.CodVisita}`}
                maxScore={5}
                size={15}
                noBorder={true}
                rating={
                  item.ProcessoFinanceiro === ""
                    ? 1
                    : parseInt(item.ProcessoFinanceiro)
                }
                starIcon={Star}
                colors={{
                  mask: "#f3cc00",
                  stroke: "#f3cc00",
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
}
